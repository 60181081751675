import React, { useState, Fragment } from "react";
import { Link } from "gatsby";

import { upComingEventState } from "./upcomingEventState";
import { pastEventState } from "./pastEventsState";
import IndivEvent from "./indivevent";

const UpComingEvent = () => {
  const [currentActiveTab, setCurrentActiveTab] = useState("all");
  const showInfoActiveTab = tab => {
    setCurrentActiveTab(tab);
  };

  const upComingEvent = upComingEventState.map((events, index) => {
    return <IndivEvent key={index} events={events} isWithLabel={false} />;
  });

  const pastEvent = pastEventState.map((events, index) => {
    return <IndivEvent key={index} events={events} isWithLabel={false} />;
  });

  return (
    <section>
      <div className="containerWrapper">
        <div className="upComingEventWrapper">
          <div className="eventsHeaderWrapper">
            <h2 className="articleSubTitle">More Events</h2>
            <div className="flexOne">
              <div className="buttonWrapper">
                <button
                  className={
                    "commonAction " +
                    (currentActiveTab === "all" ? "commonBlueBtn" : "btnTransparent")
                  }
                  onClick={() => showInfoActiveTab("all")}
                >
                  All
                </button>
                <button
                  className={
                    "commonAction " +
                    (currentActiveTab === "upcoming" ? "commonBlueBtn" : "btnTransparent")
                  }
                  onClick={() => showInfoActiveTab("upcoming")}
                >
                  Upcoming
                </button>
                <button
                  className={
                    "commonAction " +
                    (currentActiveTab === "recorded" ? "commonBlueBtn" : "btnTransparent")
                  }
                  onClick={() => showInfoActiveTab("recorded")}
                >
                  Recorded
                </button>
              </div>
            </div>
          </div>
          <div className="indivEventsWrapper">
            {currentActiveTab === "all" && (
              <Fragment>
                {upComingEvent}
                {pastEvent}
              </Fragment>
            )}
            {currentActiveTab === "upcoming" && <Fragment>{upComingEvent}</Fragment>}
            {currentActiveTab === "recorded" && <Fragment>{pastEvent}</Fragment>}
          </div>
          <div className="buttonWrapper">
            <Link to="/events/archive/">
              <button className="commonAction commonBlueBtn viewArchiveBtn">View Archive</button>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default UpComingEvent;
