import {
  FEB_2022_COMMUNITY_CALL_URL,
  MARCH_2022_COMMUNITY_CALL_URL,
  APRIL_2022_COMMUNITY_CALL_URL,
  MAY_2022_COMMUNITY_CALL_URL,
  JULY_2022_COMMUNITY_CALL_URL,
  AUG_2022_COMMUNITY_CALL_URL,
  SEP_2022_COMMUNITY_CALL_URL,
  OCT_2022_COMMUNITY_CALL_URL,
  DEC_2022_COMMUNITY_CALL_URL,
  JAN_2023_COMMUNITY_CALL_URL,
  FEB_2023_COMMUNITY_CALL_URL,
  MARCH_2023_COMMUNITY_CALL_URL,
  APRIL_2023_COMMUNITY_CALL_URL,
  MAY_2023_COMMUNITY_CALL_URL,
  JULY_2023_COMMUNITY_CALL_URL,
  AUG_2023_COMMUNITY_CALL_URL,
} from "../../utils/communityCallRoutes";

import sdTimes from "./images/sd-webinar-event-listing.png";
import multiTenantHealthcare from "./images/multi-tenant-healthcare.png";
import journalismFindingStories from "./images/journalism-finding-stories.png";
import diagnosingImprovingQueryPerformanceImg from "./images/diagnosing-improving-query-performance-event.png";
import graphqlNestjsHasuraImg from "./images/graphql-nestjs-hasura-event.png";
import hasuraTypescriptImg from "./images/hasura-typescript-event.png";
import graphqlApiSecurityWithHasuraImg from "./images/graphql-api-security-with-hasura-event.png";

export const pastEventState = [
  {
    demoType: "Community Call",
    imgUrl:
      "https://res.cloudinary.com/dh8fp23nd/image/upload/v1690540929/community-call_l9qcu4.png",
    title: "Hasura Community Call Launch Day - Aug 2023",
    register: "View Recording",
    registerLink: AUG_2023_COMMUNITY_CALL_URL,
    internalLink: true,
    // calendarDate: "Jul 27, 2023",
  },
  {
    demoType: "Webinar",
    imgUrl: "https://res.cloudinary.com/dh8fp23nd/image/upload/v1692197757/snowflake_c7vt47.png",
    title: "Accelerate app dev on Snowflake with low-code APIs",
    register: "View Recording",
    registerLink: "/events/webinar/accelerate-app-dev-on-snowflake-with-low-code-apis/",
    internalLink: true,
  },
  {
    demoType: "Community Call",
    imgUrl:
      "https://res.cloudinary.com/dh8fp23nd/image/upload/v1685083056/comcall-thumbnail_1_iz1cbw.png",
    title: "Hasura Community Call - Jul 2023",
    register: "View Recording",
    registerLink: JULY_2023_COMMUNITY_CALL_URL,
    internalLink: true,
    // calendarDate: "Jul 27, 2023",
  },
  {
    demoType: "Conference",
    imgUrl:
      "https://res.cloudinary.com/dh8fp23nd/image/upload/v1683654925/event-listing-image_je0kw6.png",
    title: "HasuraCon 2023",
    register: "View Recording",
    registerLink: "/events/hasura-con-2023/",
    internalLink: true,
    // calendarDate: "Jun 20-22, 2023",
  },
  {
    demoType: "Webinar",
    imgUrl:
      "https://res.cloudinary.com/dh8fp23nd/image/upload/v1685106056/main-web/event-listing-image_1_btnxps.png",
    title: "Instant and flavourful APIs: Serving up endpoints faster than 2-minute noodles",
    register: "View Recording",
    registerLink:
      "/events/instant-and-flavourful-apis-serving-up-endpoints-faster-than-2-minute-noodles/",
    internalLink: true,
    // calendarDate: "Jun 14, 2023",
  },
  {
    demoType: "Community Call",
    imgUrl:
      "https://res.cloudinary.com/dh8fp23nd/image/upload/v1682665304/main-web/comcall-thumbnail_3_x0jx7k.jpg",
    title: "Hasura Community Call - May 2023",
    register: "View Recording",
    registerLink: MAY_2023_COMMUNITY_CALL_URL,
    internalLink: true,
    // calendarDate: "May 25, 2023",
  },
  {
    demoType: "Conference",
    imgUrl:
      "https://res.cloudinary.com/dh8fp23nd/image/upload/v1677140056/main-web/api-days-new-york_lgil5e.png",
    title: "API Days NYC 2023 - Schema-first vs Code-first vs Model-first GraphQL",
    register: "View Recording",
    internalLink: true,
    registerLink: "/events/api-days-newyork/",
    // calendarDate: "May 16-17, 2023",
  },
  {
    demoType: "Webinar",
    imgUrl:
      "https://res.cloudinary.com/dh8fp23nd/image/upload/v1682605819/main-web/db-pre-launch-listing_a2wgoy.png",
    title: "Ship faster with low-code APIs on MySQL, MariaDB, and Oracle",
    register: "View Recording",
    registerLink: "/events/webinar/product-launch/",
    internalLink: true,
    // calendarDate: "May 18, 2023",
  },
  {
    demoType: "Webinar",
    imgUrl:
      "https://res.cloudinary.com/dh8fp23nd/image/upload/v1682000879/main-web/automatic-graphql-event-listing_dhcfkh.png",
    title: "Learn how to automatically build GraphQL APIs with ChatGPT & Hasura",
    register: "View Recording",
    registerLink:
      "/events/webinar/learn-how-to-automatically-build-graphql-apis-with-chatgpt-and-hasura/",
    internalLink: true,
    // calendarDate: "May 10, 2023",
  },
  {
    demoType: "Community Call",
    imgUrl:
      "https://res.cloudinary.com/dh8fp23nd/image/upload/v1680244521/main-web/comcall-thumbnail_e0fnlf.png",
    title: "Hasura Community Call - Apr 2023",
    register: "View Recording",
    registerLink: APRIL_2023_COMMUNITY_CALL_URL,
    internalLink: true,
    // calendarDate: "Mar 30, 2023",
  },
  {
    demoType: "Webinar",
    imgUrl:
      "https://res.cloudinary.com/dh8fp23nd/image/upload/v1676982097/main-web/snowflake-and-postgresql-event-listing_v2vuvl.png",
    title: "Combining Snowflake and PostgreSQL to build low-latency apps - APCJ",
    register: "View Recording",
    registerLink: "/events/webinar/snowflake-and-postgresql-apcj/",
    internalLink: true,
    // calendarDate: "Apr 27, 2023",
  },
  {
    demoType: "Webinar",
    imgUrl:
      "https://res.cloudinary.com/dh8fp23nd/image/upload/v1679042870/main-web/Group_11501_1.jpg",
    title: "Learn how to use Instant GraphQL APIs with Hasura & CockroachDB",
    register: "Register",
    registerLink:
      "/events/webinar/learn-how-to-use-instant-graphql-apis-with-hasura-and-cockroachdb/",
    internalLink: true,
    calendarDate: "View Recording",
  },
  {
    demoType: "Community Call",
    imgUrl:
      "https://res.cloudinary.com/dh8fp23nd/image/upload/v1677233560/main-web/comcall-thumbnail_3_1_kkxbtt.jpg",
    title: "Hasura Community Call - March 2023",
    register: "View Recording",
    registerLink: MARCH_2023_COMMUNITY_CALL_URL,
    internalLink: true,
    // calendarDate: "Mar 30, 2023",
  },
  {
    demoType: "Webinar",
    imgUrl:
      "https://res.cloudinary.com/dh8fp23nd/image/upload/v1678108437/main-web/event-listing-image_huuqko.png",
    title: "How Momentum Built a Secure and Scalable Multi-Tenant Application with Hasura",
    registerLink:
      "/events/webinar/how-momentum-built-a-secure-and-scalable-multi-tenant-application-with-hasura/",
    internalLink: true,
    register: "View Recording",
  },
  {
    demoType: "Webinar",
    imgUrl:
      "https://res.cloudinary.com/dh8fp23nd/image/upload/v1678210233/main-web/event-listing-image_g6itqv.jpg",
    title: "Optimizing your API for performance at scale",
    registerLink: "/events/webinar/optimizing-your-api-for-performance-at-scale/",
    internalLink: true,
    register: "View Recording",
  },
  {
    demoType: "Webinar",
    imgUrl:
      "https://res.cloudinary.com/dh8fp23nd/image/upload/v1677249749/main-web/securing-event-listing_rfnioi.png",
    title: "Securing your API with Hasura",
    registerLink: "/events/webinar/securing-your-api-with-hasura/",
    internalLink: true,
    register: "View Recording",
  },
  {
    demoType: "Webinar",
    imgUrl:
      "https://res.cloudinary.com/dh8fp23nd/image/upload/v1677250907/main-web/build-your-own-chat-gpt-bot-event-listing_rpn7dl.png",
    title: "Hack it with Hasura: Build your own Q&A help bot using GPT-3 & Hasura",
    registerLink: "/events/webinar/hack-it-with-hasura-build-your-own-chat-gpt-bot/",
    internalLink: true,
    register: "View Recording",
  },
  {
    demoType: "Webinar",
    imgUrl:
      "https://res.cloudinary.com/dh8fp23nd/image/upload/v1677250421/main-web/api-observability-event-listing_nzohe5.png",
    title: "Best Practices for API Observability with Hasura",
    registerLink: "/events/webinar/best-practices-for-api-observability-with-hasura/",
    internalLink: true,
    register: "View Recording",
  },
  {
    demoType: "Webinar",
    imgUrl:
      "https://res.cloudinary.com/dh8fp23nd/image/upload/v1676982097/main-web/snowflake-and-postgresql-event-listing_v2vuvl.png",
    title:
      "Combining Snowflake and PostgreSQL to build low-latency apps on historical data insights",
    registerLink: "/events/webinar/snowflake-and-postgresql/",
    internalLink: true,
    register: "View Recording",
  },
  {
    demoType: "Webinar",
    imgUrl:
      "https://res.cloudinary.com/dh8fp23nd/image/upload/v1673525207/main-web/lineup-ninja_wxzfqw.png",
    title:
      "Scaling a real-time application: Lineup Ninja's migration journey from Firebase to Hasura",
    registerLink:
      "/events/webinar/scaling-a-realtime-application-lineup-ninjas-migration-journey-from-firebase-to-hasura/",
    internalLink: true,
    // calendarDate: "Jan 31, 2023",
    register: "View Recording",
  },
  {
    demoType: "Community Call",
    imgUrl:
      "https://res.cloudinary.com/dh8fp23nd/image/upload/v1675012259/main-web/comcall-thumbnail_2_ckx2at.jpg",
    title: "Hasura Community Call - Feb 2023",
    register: "View Recording",
    registerLink: FEB_2023_COMMUNITY_CALL_URL,
    internalLink: true,
    // calendarDate: "Jan 26, 2023",
  },
  {
    demoType: "Community Call",
    imgUrl:
      "https://res.cloudinary.com/dh8fp23nd/image/upload/v1671523338/main-web/comcall-thumbnail_1_cmslpn.jpg",
    title: "Hasura Community Call - Jan 2023",
    register: "View Recording",
    registerLink: JAN_2023_COMMUNITY_CALL_URL,
    internalLink: true,
    // calendarDate: "Jan 26, 2023",
  },
  {
    demoType: "Webinar",
    imgUrl:
      "https://res.cloudinary.com/dh8fp23nd/image/upload/v1670325087/main-web/event-listing-image_1_1_1_mhrquj.jpg",
    title: "Instant GraphQL APIs on Snowflake with Hasura",
    register: "View Recording",
    registerLink: "/events/webinar/instant-graphql-apis-on-snowflake-with-hasura/",
    internalLink: true,
  },
  {
    demoType: "Webinar",
    imgUrl: "https://graphql-engine-cdn.hasura.io/assets/main-site/events/oreilly.png",
    title: "The GraphQL Data API Is the Key to Modern Product Development",
    register: "View Recording",
    registerLink: "/events/webinar/the-graphql-data-api-is-the-key-to-modern-product-development/",
    internalLink: true,
  },
  {
    demoType: "Community Call",
    imgUrl:
      "https://res.cloudinary.com/dh8fp23nd/image/upload/v1670846770/main-web/comm_call_dec22_wjacpf.jpg",
    title: "Hasura Community Call - Dec 2022",
    register: "View Recording",
    registerLink: DEC_2022_COMMUNITY_CALL_URL,
    internalLink: true,
  },
  {
    demoType: "Conference",
    imgUrl: "https://graphql-engine-cdn.hasura.io/assets/main-site/egc_event_listing.png",
    title: "Enterprise GraphQL Conference",
    desc: "",
    register: "View Recording",
    registerLink: "/enterprisegraphql/",
    internalLink: true,
    // calendarDate: "Nov 10, 2022",
  },
  {
    demoType: "Community Call",
    imgUrl:
      "https://res.cloudinary.com/dh8fp23nd/image/upload/v1666888277/main-web/comm_call_oct22_ik7wvu.jpg",
    title: "Hasura Community Call - Oct 2022",
    register: "View Recording",
    registerLink: OCT_2022_COMMUNITY_CALL_URL,
    internalLink: true,
  },
  {
    demoType: "Community Call",
    imgUrl: "https://graphql-engine-cdn.hasura.io/assets/main-site/sep_comm_listing.jpg",
    title: "Hasura Community Call - Sep 2022",
    register: "View Recording",
    registerLink: SEP_2022_COMMUNITY_CALL_URL,
    internalLink: true,
    // calendarDate: "Sep 29, 2022",
  },
  {
    demoType: "Webinar",
    imgUrl: "https://graphql-engine-cdn.hasura.io/assets/main-site/linux_foundation.png",
    title: "GraphQL To Simplify Data Access For Cloud Native",
    register: "View Recording",
    registerLink: `/events/webinar/graphql-to-simplify-data-access-for-cloud-native/`,
    internalLink: true,
    // calendarDate: "Sep 15, 2022",
  },
  {
    demoType: "Webinar",
    imgUrl: `https://graphql-engine-cdn.hasura.io/assets/main-site/event_streaming_webinar.jpg`,
    title: "Instant streaming APIs on Postgres, with built-in auth",
    register: "View Recording",
    registerLink: "/events/webinar/instant-streaming-apis-on-postgres-with-built-in-auth/",
    internalLink: true,
    // calendarDate: "Sep 21, 2022",
  },
  {
    demoType: "Webinar",
    imgUrl:
      "https://res.cloudinary.com/dh8fp23nd/image/upload/v1669283647/main-web/postman-host_doxiwc.jpg",
    title: "Hasura Hosts Postman: Exploring the State of the API",
    register: "View Recording",
    registerLink: "/events/webinar/hasura-hosts-postman/",
    internalLink: true,
    // calendarDate: "Aug 31, 2022",
  },
  {
    demoType: "Community Call",
    imgUrl:
      "https://res.cloudinary.com/dh8fp23nd/image/upload/v1669283647/main-web/hasura_aug_2022_1_1_lebfsj.jpg",
    title: "Hasura Community Call - Aug 2022",
    register: "View Recording",
    registerLink: AUG_2022_COMMUNITY_CALL_URL,
    internalLink: true,
    // calendarDate: "Aug 25, 2022",
  },
  {
    demoType: "Webinar",
    imgUrl: sdTimes,
    title: "GraphQL Security: Something Old and Something New",
    register: "View Recording",
    registerLink: "/events/webinar/graphql-security-something-old-and-something-new/",
    internalLink: true,
    // calendarDate: "Jul 26, 2022",
  },
  {
    demoType: "Webinar",
    imgUrl: "https://graphql-engine-cdn.hasura.io/assets/main-site/linux_foundation.png",
    title: "Write Less Code",
    register: "View Recording",
    registerLink: "/events/webinar/write-less-code/",
    internalLink: true,
    // calendarDate: "Jul 21, 2022",
  },
  {
    demoType: "Webinar",
    imgUrl:
      "https://res.cloudinary.com/dh8fp23nd/image/upload/v1669283996/main-web/ultimate-data-api_gwleum.jpg",
    title: "The Path to the Ultimate Data API with GraphQL: the Why, the How and the Future",
    register: "View Recording",
    registerLink:
      "/events/webinar/the-path-to-the-ultimate-data-api-with-graphql-and-the-why-the-how-and-the-future/",
    internalLink: true,
    // calendarDate: "Jul 21, 2022",
  },
  {
    demoType: "Webinar",
    imgUrl: multiTenantHealthcare,
    title: "Incrementally Adopting Hasura in a Multi-Tenant Healthcare SaaS App",
    register: "View Recording",
    registerLink:
      "/events/webinar/incrementally-adopting-hasura-in-a-multi-tenant-healthcare-saas-app/",
    internalLink: true,
    // calendarDate: "Jul 21, 2022",
  },
  {
    demoType: "Webinar",
    imgUrl:
      "https://res.cloudinary.com/dh8fp23nd/image/upload/v1669283996/main-web/observability-hasura-based_z1yvu3.jpg",
    title: "Observability for your Hasura-Based Apps",
    register: "View Recording",
    registerLink: "/events/webinar/observability-for-your-hasura-based-apps/",
    internalLink: true,
    // calendarDate: "Jul 21, 2022",
  },
  {
    demoType: "Webinar",
    imgUrl:
      "https://res.cloudinary.com/dh8fp23nd/image/upload/v1669283996/main-web/ci-cd-workflows_xmza2b.jpg",
    title: "CI/CD Workflows with Hasura Cloud",
    register: "View Recording",
    registerLink: "/events/webinar/ci-cd-workflows-with-hasura-cloud/",
    internalLink: true,
    // calendarDate: "Jul 21, 2022",
  },
  {
    demoType: "Webinar",
    imgUrl:
      "https://res.cloudinary.com/dh8fp23nd/image/upload/v1669284283/main-web/serverless-graphql-api-beb40ea4e5e0a33aeca0224b695ec6f5_fim2cs.jpg",
    title: "Build an End-to-end Serverless GraphQL API with Hasura and Azure SQL Database",
    register: "View Recording",
    registerLink:
      "/events/webinar/build-an-end-to-end-serverless-graphql-api-with-hasura-and-azure-sql-database/",
    internalLink: true,
    // calendarDate: "Jul 21, 2022",
  },
  {
    demoType: "Webinar",
    imgUrl: journalismFindingStories,
    title: "Hasura in Journalism: Finding Stories and Speeding up Fact-Checking",
    register: "View Recording",
    registerLink:
      "/events/webinar/hasura-in-journalism-finding-stories-and-speeding-up-fact-checking/",
    internalLink: true,
    // calendarDate: "Jul 21, 2022",
  },
  {
    demoType: "Webinar",
    imgUrl:
      "https://res.cloudinary.com/dh8fp23nd/image/upload/v1669284283/main-web/hasura_neon_r5o6g0.jpg",
    title: "Serverless Postgres Built for Developer Experience",
    register: "View Recording",
    registerLink: "/events/webinar/hasura-hosts-neon/",
    internalLink: true,
    // calendarDate: "Jul 26, 2022",
  },
  {
    demoType: "Community Call",
    imgUrl:
      "https://res.cloudinary.com/dh8fp23nd/image/upload/v1669284871/main-web/july_2022_community_call_1_1_dwjdlx.jpg",
    title: "Hasura Community Call - Jul 2022",
    desc: "A monthly call featuring demos, features, & product updates by the Hasura engineers.",
    register: "View Recording",
    registerLink: JULY_2022_COMMUNITY_CALL_URL,
    internalLink: true,
    // calendarDate: "Jul 28, 2022",
  },
  {
    demoType: "Webinar",
    imgUrl:
      "https://res.cloudinary.com/dh8fp23nd/image/upload/v1669284871/main-web/yugabyte-host-065cf8aa3e6ad01e0104a3987efc3aa7_1_wvttjg.jpg",
    title: "How to build an app at Facebook-scale in 2022",
    register: "View Recording",
    registerLink: "/events/webinar/hasura-hosts-yugabyte/",
    internalLink: true,
    // calendarDate: "Jun 02, 2022",
  },
  {
    demoType: "Webinar",
    imgUrl:
      "https://res.cloudinary.com/dh8fp23nd/image/upload/v1669284871/main-web/hasura-hosts-cockroach-events-c7fc8f229c46c39fefcbf63a34524443_1_f8wc1k.jpg",
    title: "Serverless data for APIs and product development at the edge",
    register: "View Recording",
    registerLink: "/events/webinar/hasura-hosts-cockroach/",
    internalLink: true,
    // calendarDate: "Jun 02, 2022",
  },
  {
    demoType: "Webinar",
    imgUrl: "https://graphql-engine-cdn.hasura.io/assets/main-site/hasura-graphQL-joins.jpg",
    title: "A Deep Dive into Data Federation with GraphQL Joins",
    register: "View Recording",
    registerLink: "/events/webinar/a-deep-dive-into-data-federation-with-graphql-joins/",
    internalLink: true,
    // calendarDate: "May 12, 2022",
  },
  {
    demoType: "Community Call",
    imgUrl:
      "https://res.cloudinary.com/dh8fp23nd/image/upload/v1669284871/main-web/community-call-may-2022-event-4544ee08e6c910c2ecd277343216b3ce_1_zy7hcn.jpg",
    title: "Hasura Community Call - May 2022",
    desc: "A monthly call featuring demos, features, & product updates by the Hasura engineers.",
    register: "View Recording",
    registerLink: MAY_2022_COMMUNITY_CALL_URL,
    internalLink: true,
    // calendarDate: "May 26, 2022",
  },
  {
    demoType: "Community Call",
    imgUrl:
      "https://res.cloudinary.com/dh8fp23nd/image/upload/v1669284871/main-web/april_2022_community_call_1_zihmpd.jpg",
    title: "Hasura Community Call - Apr 2022",
    desc: "A monthly call featuring demos, features, & product updates by the Hasura engineers.",
    register: "View Recording",
    registerLink: APRIL_2022_COMMUNITY_CALL_URL,
    internalLink: true,
    // calendarDate: "Apr 28, 2022",
  },
  {
    demoType: "Community Call",
    imgUrl:
      "https://res.cloudinary.com/dh8fp23nd/image/upload/v1669285453/main-web/mar-community-call-faa416f04c38b83d16d5d589060ab94e_zqwbme.jpg",
    title: "Hasura Community Call - Mar 2022",
    desc: "A monthly call featuring demos, features, & product updates by the Hasura engineers.",
    register: "View Recording",
    registerLink: MARCH_2022_COMMUNITY_CALL_URL,
    internalLink: true,
    // calendarDate: "Mar 31, 2022",
  },
  {
    demoType: "Community Call",
    imgUrl:
      "https://res.cloudinary.com/dh8fp23nd/image/upload/v1669286033/main-web/Screenshot_2022-11-24_at_3.58.55_PM_feb4fk.png",
    title: "Hasura Community Call - Feb 2022",
    desc: "A monthly call featuring demos, features, & product updates by the Hasura engineers.",
    register: "View Recording",
    registerLink: FEB_2022_COMMUNITY_CALL_URL,
    internalLink: true,
    // calendarDate: "Feb 24, 2022",
  },
  {
    demoType: "Webinar",
    imgUrl:
      "https://res.cloudinary.com/dh8fp23nd/image/upload/v1669285453/main-web/rest-connectors-event-e7bc5fe93a12b41b460c626209e21162_n5wxji.jpg",
    title: "An introduction to REST connectors in Hasura",
    desc: "Learn about how you can integrate Hasura with REST APIs directly through newly launched Action, Event, and CRON Transforms.",
    register: "View Recording",
    registerLink: "/events/webinar/rest-connectors/",
    internalLink: true,
    // calendarDate: "20 Jan 2022",
  },
  {
    demoType: "Community Call",
    imgUrl:
      "https://res.cloudinary.com/dh8fp23nd/image/upload/v1669286033/main-web/Screenshot_2022-11-24_at_4.00.19_PM_jlr4sd.png",
    title: "Hasura Community Call - Jan 2022",
    desc: "A monthly call featuring demos, features, & product updates by the Hasura engineers.",
    register: "View Recording",
    registerLink: "https://www.youtube.com/watch?v=IUrmOAYhxco",
    internalLink: false,
  },
  {
    demoType: "Community Call",
    imgUrl:
      "https://res.cloudinary.com/dh8fp23nd/image/upload/v1669286033/main-web/Screenshot_2022-11-24_at_4.00.27_PM_wdcefz.png",
    title: "Hasura Community Call - Dec 2021",
    desc: "A monthly call featuring demos, features, & product updates by the Hasura engineers.",
    register: "View Recording",
    registerLink:
      "https://www.youtube.com/watch?v=vVa0AZmuMzs&list=PLTRTpHrUcSB8Dp3hk5SiVNHNPJdKNHzFW&index=3",
    internalLink: false,
  },
  {
    demoType: "Webinar",
    imgUrl:
      "https://res.cloudinary.com/dh8fp23nd/image/upload/v1669286033/main-web/Screenshot_2022-11-24_at_4.02.30_PM_dyeoku.png",
    title: "Replacing Shopify in 4 weeks with Hasura and React ",
    desc: "Learn how Cajoo used Hasura to bring their e-commerce stack in-house while scaling to hundreds of thousands of users with a super lean engineering team.",
    register: "View Recording",
    registerLink:
      "/events/webinar/replatformin-from-a-shopify-backend-to-hasura-for-flexibility-and-speed-the-cajoo-story/",
    internalLink: true,
  },
  {
    demoType: "Conference",
    imgUrl:
      "https://res.cloudinary.com/dh8fp23nd/image/upload/v1669286300/main-web/egc-event-4c76f80b5c4e483cfaf02406ec2ba583_1_prtv7w.jpg",
    title: "GraphQL and The Data Mesh - Enterprise GraphQL Conference 2021",
    desc: "Federating data with GraphQL and the concept of Data Oriented Architectures - Join us to explore how GraphQL is being used to solve data challenges for applications at scale.",
    register: "View Recording",
    registerLink: "/enterprisegraphql/2021/",
    internalLink: true,
  },
  {
    demoType: "Community Call",
    imgUrl:
      "https://res.cloudinary.com/dh8fp23nd/image/upload/v1669286300/main-web/sep_community-71021d3769942c20f1f221843454607c_1_cikyyj.jpg",
    title: "Hasura Community Call - Sep 2021",
    desc: "A monthly call featuring demos, features, & product updates by the Hasura engineers.",
    register: "View Recording",
    registerLink:
      "https://www.youtube.com/watch?v=kY_1-Q2opvA&list=PLTRTpHrUcSB8Dp3hk5SiVNHNPJdKNHzFW&index=3",
    internalLink: false,
  },
  {
    demoType: "Workshop",
    imgUrl:
      "https://res.cloudinary.com/dh8fp23nd/image/upload/v1669286300/main-web/Screenshot_2022-11-24_at_4.06.42_PM_1_q1nbep.jpg",
    title: "Automated API Deployments with GitHub Integration on Hasura Cloud",
    desc: "Learn how you can apply any schema or metadata changes automatically with a simple git push to a configured branch on a GitHub repo using Hasura Cloud.",
    register: "View Recording",
    registerLink:
      "/events/webinar/automated-api-deployments-with-github-integration-on-hasura-cloud/",
    internalLink: true,
  },
  {
    demoType: "Webinar",
    imgUrl:
      "https://res.cloudinary.com/dh8fp23nd/image/upload/v1669290992/main-web/Screenshot_2022-11-24_at_5.22.42_PM_xvbuw5.jpg",
    title: "How Hasura optimizes GraphQL API Performance",
    desc: "Learn how Hasura optimizes your API’s performance and scales both vertically and horizontally.",
    register: "View Recording",
    registerLink: "/events/webinar/query-performance-with-hasura/",
    internalLink: true,
  },
  {
    demoType: "Webinar",
    imgUrl: diagnosingImprovingQueryPerformanceImg,
    title: "GraphQL Observability to Diagnose & Improve Query Performance for your Hasura apps",
    desc: "Learn how you can debug & analyze query performance in your Hasura applications using the monitoring and observability features that Hasura provides.",
    register: "View Recording",
    registerLink: "/events/webinar/diagnosing-improving-query-performance/",
    internalLink: true,
  },
  {
    demoType: "Workshop",
    imgUrl: graphqlNestjsHasuraImg,
    title: "Fullstack GraphQL Apps with NestJS and Hasura",
    desc: "A 90-minute workshop on how to build fullstack GraphQL apps using NestJS, TypeScript, and Hasura.",
    register: "View Recording",
    registerLink: "/events/workshop/graphql-nestjs-hasura/",
    internalLink: true,
  },
  {
    demoType: "Community Call",
    imgUrl:
      "https://res.cloudinary.com/dh8fp23nd/image/upload/v1669288322/main-web/Screenshot_2022-11-24_at_4.26.44_PM_1_khpfue.jpg",
    title: "Hasura Community Call - Aug 2021",
    desc: "A monthly call featuring demos, features, & product updates by the Hasura engineers.",
    register: "View Recording",
    registerLink: "https://www.youtube.com/watch?v=tKHDtdV0HJI",
    internalLink: false,
  },
  {
    demoType: "Webinar",
    imgUrl: graphqlApiSecurityWithHasuraImg,
    title: "GraphQL API Security with Hasura",
    desc: "Overview of security features available on Hasura to protect your application against injection attacks, DDoS attacks, excessive data exposure, and more.",
    register: "View Recording",
    registerLink: "/events/webinar/graphql-api-security-with-hasura/",
    internalLink: true,
  },
  {
    demoType: "Webinar",
    imgUrl:
      "https://res.cloudinary.com/dh8fp23nd/image/upload/v1669290992/main-web/Screenshot_2022-11-24_at_5.22.03_PM_urjyfj.jpg",
    title: "Model Level Authorization System for GraphQL with Hasura",
    desc: "A hands-on demo and guide to Hasura’s Authorization Model for your GraphQL APIs.",
    register: "View Recording",
    registerLink: "/events/webinar/model-level-authorization/",
    internalLink: true,
  },
  {
    demoType: "Community Call",
    imgUrl:
      "https://res.cloudinary.com/dh8fp23nd/image/upload/v1669288322/main-web/community-call-jul-event-503e59ad99f35b6aca6470e5bde10127_1_1_k0pgle.jpg",
    title: "Hasura Community Call - Jul 2021",
    desc: "A monthly call featuring demos, features, & product updates by the Hasura engineers.",
    register: "View Recording",
    registerLink: "https://www.youtube.com/watch?v=3LarKsNNBaE",
  },
  {
    demoType: "Workshop",
    imgUrl: hasuraTypescriptImg,
    title: "Generated TypeScript Tooling with Hasura",
    desc: "Hasura gives you GraphQL without writing resolvers. This workshop gives you TypeScript without any boilerplate.",
    register: "View Recording",
    registerLink: "/events/workshop/hasura-typescript/",
    internalLink: true,
  },
  {
    demoType: "Community Call",
    imgUrl:
      "https://res.cloudinary.com/dh8fp23nd/image/upload/v1669288322/main-web/community-call-may-event-b70218121b0988b8d2d9ad89b4ac327b_1_1_1_mypcyd.jpg",
    title: "Hasura Community Call - May 2021",
    desc: "A monthly call featuring demos, features, & product updates by the Hasura engineers.",
    register: "View Recording",
    registerLink: "https://www.youtube.com/watch?v=65Er7VCF5Dw",
  },
  {
    demoType: "Webinar",
    imgUrl:
      "https://res.cloudinary.com/dh8fp23nd/image/upload/v1669288322/main-web/Screenshot_2022-11-24_at_4.35.03_PM_chprkm.jpg",
    title: "Getting Started with Hasura",
    desc: "This official getting started with Hasura video, walks you through everything you need to know to get your first secure API up and running with Hasura.",
    register: "View Recording",
    registerLink: "/events/webinar/get-started-with-hasura/",
    internalLink: true,
  },
  {
    demoType: "Conference",
    imgUrl:
      "https://res.cloudinary.com/dh8fp23nd/image/upload/v1669290226/main-web/Screenshot_2022-11-24_at_4.32.42_PM_zwoquf.png",
    title: "HasuraCon - The Global Hasura User Conference",
    desc: "HasuraCon is a free, online conference. Featuring talks and live, hand-on workshops!",
    register: "View Recording",
    registerLink: "/events/hasura-con-2021/recordings/",
    internalLink: true,
  },
  {
    demoType: "Webinar",
    imgUrl:
      "https://res.cloudinary.com/dh8fp23nd/image/upload/v1669290644/main-web/Screenshot_2022-11-24_at_4.32.34_PM_bzl2i7.png",
    title: "How MachineMetrics Used Hasura to Accomplish In 2 Days What Used to Take 2 Weeks",
    desc: "Learn how Machine Metrics adopted Hasura's GraphQL Engine",
    register: "View Recording",
    registerLink: "/events/webinar/hasura-machine-metrics/",
    internalLink: true,
  },
  {
    demoType: "Workshop",
    imgUrl:
      "https://res.cloudinary.com/dh8fp23nd/image/upload/v1669290404/main-web/Screenshot_2022-11-24_at_4.32.27_PM_gtrhio.jpg",
    title: "Build a powerful backend for JAMStack apps using Hasura and Strapi",
    desc: "An online, hands-on workshop on using Hasura, GraphQL and Strapi to build a backend for a Jamstack app",
    register: "View Recording",
    registerLink:
      "/events/workshop/build-a-powerful-backend-for-jamstack-apps-using-hasura-and-strapi/",
    internalLink: true,
  },
  {
    demoType: "Hands-on Demo",
    imgUrl:
      "https://res.cloudinary.com/dh8fp23nd/image/upload/v1669290403/main-web/Screenshot_2022-11-24_at_4.32.13_PM_f60xl5.jpg",
    title: "Hasura with SQL Server 101",
    desc: "A hands-on demo to create GraphQL & REST APIs in Minutes",
    register: "View Recording",
    registerLink: "/events/webinar/hasura-sql-server/",
    internalLink: true,
  },
  {
    demoType: "Webinar",
    imgUrl:
      "https://res.cloudinary.com/dh8fp23nd/image/upload/v1669290404/main-web/Screenshot_2022-11-24_at_4.32.21_PM_hexelk.jpg",
    title: "Learn How Pipe Uses Hasura To Help Companies Grow On Their Terms",
    desc: "Hasura and Pipe joint webinar: Learn how Pipe is using Hasura to easily create GraphQL APIs to ship features quickly",
    register: "View Recording",
    registerLink: "/events/webinar/hasura-pipe/",
    internalLink: true,
  },
  {
    demoType: "Conference",
    imgUrl:
      "https://res.cloudinary.com/dh8fp23nd/image/upload/v1669289878/main-web/Screenshot_2022-11-24_at_4.32.05_PM_omicjk.png",
    title: "GraphQL Asia 2021",
    desc: "Asia's biggest GraphQL Conference is back in Feb 2021 and registrations are open!",
    register: "Watch Videos",
    registerLink: "https://graphql.asia/",
  },
  {
    demoType: "Webinar",
    imgUrl:
      "https://res.cloudinary.com/dh8fp23nd/image/upload/v1669290227/main-web/sql-server-event-8a0352524270b031f049fb26382845cd_1_ut685h.jpg",
    title: "SQL Server Support & Our Biggest Release Since Launching Hasura",
    desc: "Unveiling our new mission-critical features and improvements",
    register: "View Recording",
    registerLink: "/events/webinar/hasura2/",
    internalLink: true,
  },
  {
    demoType: "Webinar",
    imgUrl:
      "https://res.cloudinary.com/dh8fp23nd/image/upload/v1669289620/main-web/Screenshot_2022-11-24_at_4.31.58_PM_o8mqfh.png",
    title: "Defining your GraphQL Strategy in 2021",
    desc: "GraphQL has been around for 5 years now. Learn where GraphQL adoption stands in 2021.",
    register: "View Recording",
    registerLink: "/events/webinar/graphql-in-2021/",
    internalLink: true,
  },
  {
    demoType: "Community Call",
    imgUrl:
      "https://res.cloudinary.com/dh8fp23nd/image/upload/v1669286033/main-web/Screenshot_2022-11-24_at_4.00.19_PM_jlr4sd.png",
    title: "Hasura Community Call - Jan 2020",
    desc: "A monthly call featuring demos, features, & product updates by the Hasura engineers.",
    register: "View Recording",
    registerLink: "https://www.youtube.com/watch?v=oXhpdAg6_nI",
  },
  {
    demoType: "Webinar",
    imgUrl:
      "https://res.cloudinary.com/dh8fp23nd/image/upload/v1669289620/main-web/Screenshot_2022-11-24_at_4.31.49_PM_pp8je9.png",
    title: "Hasura Actions: Adding or Reusing Custom Business Logic with Hasura",
    desc: "Learn how you can use Hasura Actions to add, or reuse existing custom business logic to your GraphQL API.",
    register: "View Recording",
    registerLink: "/events/webinar/hasura-actions/",
    internalLink: true,
  },
  {
    demoType: "Community Call",
    imgUrl:
      "https://res.cloudinary.com/dh8fp23nd/image/upload/v1669286033/main-web/Screenshot_2022-11-24_at_4.00.27_PM_wdcefz.png",
    title: "Hasura Community Call - Dec 2020",
    desc: "A monthly call featuring demos, features, & product updates by the Hasura engineers.",
    register: "View Recording",
    registerLink: "https://www.youtube.com/watch?v=1h5zMerJiPA",
  },
  {
    demoType: "Webinar",
    imgUrl:
      "https://res.cloudinary.com/dh8fp23nd/image/upload/v1669289878/main-web/Screenshot_2022-11-24_at_4.31.20_PM_azedx4.jpg",
    title: "GraphQL Contributor Days",
    desc: "Join us for a livestreamed event with core contributors and authors of various libraries in the GraphQL Community.",
    register: "View Recording",
    registerLink: "https://www.youtube.com/watch?v=yo8AO__QEWA",
  },
  {
    demoType: "Webinar",
    imgUrl:
      "https://res.cloudinary.com/dh8fp23nd/image/upload/v1669289420/main-web/Screenshot_2022-11-24_at_4.31.30_PM_imgbtk.png",
    title: "Hasura Database Schema Migrations",
    desc: "Get an overview of Database Schema Migrations, along with some hands on coding.",
    register: "View Recording",
    registerLink: "/events/webinar/hasura-database-schema-migrations/",
    internalLink: true,
  },
  {
    demoType: "Community Call",
    imgUrl:
      "https://res.cloudinary.com/dh8fp23nd/image/upload/v1669289420/main-web/Screenshot_2022-11-24_at_4.31.39_PM_xcwr09.png",
    title: "Hasura Community Call - Oct 2020",
    desc: "A monthly call featuring demos, features, & product updates by the Hasura engineers.",
    register: "View Recording",
    registerLink: "https://www.youtube.com/watch?v=i4tEGNFkgiY",
  },
  {
    demoType: "Workshop",
    imgUrl:
      "https://res.cloudinary.com/dh8fp23nd/image/upload/v1669289111/main-web/Screenshot_2022-11-24_at_4.31.06_PM_yilrnc.png",
    title: "Building an Enterprise- grade Data Access layer",
    desc: "Build an enterprise-grade Data Access layer, secure it with authentication and authorization, and extend it for custom business logic.",
    register: "View Recording",
    registerLink: "/enterprisegraphql/2020/workshop/",
    internalLink: true,
  },
  {
    demoType: "Webinar",
    imgUrl:
      "https://res.cloudinary.com/dh8fp23nd/image/upload/v1669289110/main-web/Screenshot_2022-11-24_at_4.30.57_PM_j57ajb.png",
    title: "Enterprise Grade Authorization",
    desc: "Learn how to model popular enterprise authorization systems like RBAC, ABAC, AWS IAM and GCP IAM in Hasura to map your authorization requirements.",
    register: "View Recording",
    registerLink: "/events/webinar/authorization-modeling-hasura/",
    internalLink: true,
  },
  {
    demoType: "Webinar",
    imgUrl:
      "https://res.cloudinary.com/dh8fp23nd/image/upload/v1669288797/main-web/Screenshot_2022-11-24_at_4.30.48_PM_jekoov.png",
    title: "GraphQL in Production",
    desc: "Learn about the powerful features that allow organisations to build and maintain secure, fast and highly scalable apps using our GraphQL API",
    register: "View Recording",
    registerLink: "/events/webinar/graphql-in-production-with-hasura/",
    internalLink: true,
  },
  {
    demoType: "Conference",
    imgUrl:
      "https://res.cloudinary.com/dh8fp23nd/image/upload/v1669289276/main-web/Screenshot_2022-11-24_at_4.30.36_PM_ipnbrd.jpg",
    title: "HasuraCon'20: The first Hasura user conference",
    desc: "Our first user conference featuring a week of talks and hands-on workshops about pushing the boundaries of GraphQL forward.",
    register: "View all Talks",
    registerLink: "https://www.youtube.com/playlist?list=PLTRTpHrUcSB9BmfyXmhplz874ySHeKlYx",
  },
  {
    demoType: "Community Call",
    imgUrl:
      "https://res.cloudinary.com/dh8fp23nd/image/upload/v1669289276/main-web/Screenshot_2022-11-24_at_4.30.27_PM_vart2l.jpg",
    title: "Hasura Community Call - Sep 2020",
    desc: "A monthly call featuring demos, features, & product updates by the Hasura engineers.",
    register: "View Recording",
    registerLink: "https://www.youtube.com/watch?v=8rfAkAiE2_I",
  },
  {
    demoType: "Webinar",
    imgUrl:
      "https://res.cloudinary.com/dh8fp23nd/image/upload/v1669286033/main-web/Screenshot_2022-11-24_at_3.58.55_PM_feb4fk.png",
    title: "Data Federation with Hasura",
    desc: "Join data across your databases, microservices & SaaS services (GraphQL & REST) with Hasura",
    register: "View Recording",
    registerLink: "/events/webinar/data-federation-hasura-graphql/",
    internalLink: true,
  },
  {
    demoType: "Webinar",
    imgUrl:
      "https://res.cloudinary.com/dh8fp23nd/image/upload/v1669288848/main-web/Screenshot_2022-11-24_at_4.27.14_PM_my79dq.png",
    title: "Moving from OSS to Cloud",
    desc: "Learn how to migrate a Hasura OSS instance to Cloud and why it makes sense for you.",
    register: "View Recording",
    registerLink: "/events/webinar/migrate-oss-hasura-hasura-cloud/",
    internalLink: true,
  },
  {
    demoType: "Community Call",
    imgUrl:
      "https://res.cloudinary.com/dh8fp23nd/image/upload/v1669288322/main-web/Screenshot_2022-11-24_at_4.26.44_PM_1_khpfue.jpg",
    title: "Hasura Community Call - Aug 2020",
    desc: "A monthly call featuring demos, features, & product updates by the Hasura engineers.",
    register: "View Recording",
    registerLink: "https://www.youtube.com/watch?v=tFtgy4mQ68o",
  },
  {
    demoType: "Fireside Chat",
    imgUrl:
      "https://res.cloudinary.com/dh8fp23nd/image/upload/v1669288796/main-web/Screenshot_2022-11-24_at_4.27.28_PM_zpf0cs.png",
    title: "Use Angular with GraphQL",
    desc: "A chat with Bonnie Brennan, Chris Whited, Uri Goldshtein & Tanmai Gopal about the details in using GraphQL with Angular.",
    register: "View Recording",
    registerLink:
      "https://www.youtube.com/watch?v=SNMes6OZdsg&list=PLTRTpHrUcSB-dYzMN0kkujIBj2ZnUnA-Z&index=1",
  },
  {
    demoType: "Fireside Chat",
    imgUrl:
      "https://res.cloudinary.com/dh8fp23nd/image/upload/v1669288634/main-web/Screenshot_2022-11-24_at_4.27.36_PM_bgozmz.png",
    title: "Using Relay with GraphQL",
    desc: "A chat with Joseph Savona, Sean Grove, Jesse Martin & Tanmai Gopal about the intircacies in using GraphQL with Relay. ",
    register: "View Recording",
    registerLink:
      "https://www.youtube.com/watch?v=HapQ-nyT1os&list=PLTRTpHrUcSB-dYzMN0kkujIBj2ZnUnA-Z&index=2",
  },
  {
    demoType: "Fireside Chat",
    imgUrl:
      "https://res.cloudinary.com/dh8fp23nd/image/upload/v1669288634/main-web/Screenshot_2022-11-24_at_4.27.49_PM_kdg4fs.png",
    title: "Using Vue.js with GraphQL",
    desc: "A chat with Natalie Tepluhina, Debbie O'Brien & Tanmai Gopal on how to go about using Vue.js with GraphQL.",
    register: "View Recording",
    registerLink:
      "https://www.youtube.com/watch?v=ElvcUIgt7n0&list=PLTRTpHrUcSB-dYzMN0kkujIBj2ZnUnA-Z&index=4",
  },
];
