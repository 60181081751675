import React from "react";

import Layout from "../components/layout";
import Seo from "../components/seo";
import TopBanner from "../components/events/topbanner";
import UpComingEvent from "../components/events/upcomingevent";

const ogImage = {
  ogImage: "https://graphql-engine-cdn.hasura.io/assets/main-site/events.png",
};

const canonicalUrl = "https://hasura.io/events/";

const Events = props => (
  <Layout location={props.location}>
    <Seo
      title="Hasura Events | Hasura GraphQL Engine"
      description="Interested in learning more about Hasura & GraphQL? Take a look at our events below! We have both live and recorded events on everything GraphQL & Hasura."
      meta={ogImage}
      canonicalLink={canonicalUrl}
    />
    <TopBanner location={props.location} />
    <UpComingEvent />
  </Layout>
);

export default Events;
