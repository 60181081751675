import { COMMUNITY_CALL_BASE_URL } from "../../utils/communityCallRoutes";

export const upComingEventState = [
  // {
  //   demoType: "Demo",
  //   imgUrl:
  //     "https://res.cloudinary.com/dh8fp23nd/image/upload/v1669282357/main-web/Weekly_Demo_zj7rgh.jpg",
  //   title: "Up and running with Hasura",
  //   register: "Register",
  //   registerLink: "/events/weekly-demo/up-and-running-with-hasura/",
  //   internalLink: true,
  //   calendarDate: "Every Tuesday",
  // },
  // {
  //   demoType: "Webinar",
  //   imgUrl:
  //     "https://res.cloudinary.com/dh8fp23nd/image/upload/v1678108437/main-web/event-listing-image_huuqko.png",
  //   title: "How Momentum Built a Secure and Scalable Multi-Tenant Application with Hasura",
  //   registerLink:
  //     "/events/webinar/how-momentum-built-a-secure-and-scalable-multi-tenant-application-with-hasura/",
  //   internalLink: true,
  //   calendarDate: "Mar 23, 2023",
  // },
  // {
  //   demoType: "Live Event",
  //   imgUrl:
  //     "https://res.cloudinary.com/dh8fp23nd/image/upload/v1677140056/main-web/qcon-london_cvqql6.png",
  //   title: "QCon London",
  //   register: "Register",
  //   registerLink: "https://qconlondon.com/",
  //   calendarDate: "Mar 27-29, 2023",
  // },
  // {
  //   demoType: "Live Event",
  //   imgUrl:
  //     "https://res.cloudinary.com/dh8fp23nd/image/upload/v1677140056/main-web/data-council_yabsvy.png",
  //   title: "Data Council - Austin",
  //   register: "Register",
  //   registerLink: "https://www.datacouncil.ai/austin",
  //   calendarDate: "Mar 28-30, 2023",
  // },
  // {
  //   demoType: "Live Event",
  //   imgUrl:
  //     "https://res.cloudinary.com/dh8fp23nd/image/upload/v1678097243/main-web/event-thumbnail_aoyc7n.png",
  //   title: "How GraphQL Accelerates Modern Application Development",
  //   register: "Register",
  //   registerLink: `https://www.eventbrite.com/e/how-graphql-accelerates-modern-application-development-tickets-559919764087`,
  //   calendarDate: "Mar 30, 2023",
  // },
  // {
  //   demoType: "workshop",
  //   imgUrl:
  //     "https://res.cloudinary.com/dh8fp23nd/image/upload/v1678100941/main-web/event-listing-image_d1nul0.png",
  //   title: "#ShipFast on AWS with the Hasura API Platform - London",
  //   register: "Register",
  //   registerLink: "/events/workshop/ship-fast-on-aws-with-the-hasura-api-platform/",
  //   internalLink: true,
  //   calendarDate: "Mar 30, 2023",
  // },
  // {
  //   demoType: "workshop",
  //   imgUrl:
  //     "https://res.cloudinary.com/dh8fp23nd/image/upload/v1678100941/main-web/event-listing-image_d1nul0.png",
  //   title: "#ShipFast on AWS with the Hasura API Platform - New York",
  //   register: "Register",
  //   registerLink: "/events/workshop/ship-fast-on-aws-with-the-hasura-api-platform/",
  //   internalLink: true,
  //   calendarDate: "Apr 5, 2023",
  // },
  // {
  //   demoType: "Live Event",
  //   imgUrl:
  //     "https://res.cloudinary.com/dh8fp23nd/image/upload/v1675710685/main-web/roadshow/dapi-ny_acjzrg.png",
  //   title: "Data + API Days: In New York",
  //   register: "Register",
  //   registerLink: "/data-api-days/new-york/",
  //   internalLink: true,
  //   calendarDate: "Apr 06, 2023",
  // },
  // {
  //   demoType: "Live Event",
  //   imgUrl:
  //     "https://res.cloudinary.com/dh8fp23nd/image/upload/v1675710685/main-web/roadshow/dapi-sf_ie4kqd.png",
  //   title: "Data + API Days: In San Francisco",
  //   register: "Register",
  //   registerLink: "/data-api-days/san-francisco/",
  //   internalLink: true,
  //   calendarDate: "May 04, 2023",
  // },
  // {
  //   demoType: "Webinar",
  //   imgUrl:
  //     "https://res.cloudinary.com/dh8fp23nd/image/upload/v1681715449/main-web/monoliths-to-microservices-event-listing_wp4u9m.png",
  //   title: "Monoliths to Microservices: BFFs with Hasura",
  //   register: "Register",
  //   registerLink: "/events/webinar/monoliths-to-microservices-bffs-with-hasura/",
  //   internalLink: true,
  //   calendarDate: "May 11, 2023",
  // },
  // {
  //   demoType: "Conference",
  //   imgUrl:
  //     "https://res.cloudinary.com/dh8fp23nd/image/upload/v1682341577/main-web/data-innovation-summit-event_cfqaut.png",
  //   title: "Data Innovation Summit",
  //   register: "Register",
  //   registerLink: "https://datainnovationsummit.com/",
  //   calendarDate: "May 11-12, 2023",
  // },
  // {
  //   demoType: "Virtual",
  //   imgUrl:
  //     "https://res.cloudinary.com/dh8fp23nd/image/upload/v1683527918/main-web/DAPI-NY_oyhjl4.jpg",
  //   title: "Virtual Data + API Days",
  //   register: "Register",
  //   registerLink: "/data-api-days/virtual-apac/",
  //   internalLink: true,
  //   calendarDate: "May 31, 2023",
  // },
  // {
  //   demoType: "workshop",
  //   imgUrl:
  //     "https://res.cloudinary.com/dh8fp23nd/image/upload/v1685439356/main-web/hinto_s0yioc.png",
  //   title: "Hasura: fast and performant APIs, starting from your data",
  //   register: "Register",
  //   registerLink: `https://www.hinto.com/en/events/hasura-fast-and-performant-apis-starting-your-data`,
  //   internalLink: false,
  //   calendarDate: "Jun 7, 2023",
  // },
  /*{
    demoType: "Webinar",
    imgUrl:
      "https://res.cloudinary.com/dh8fp23nd/image/upload/v1685106056/main-web/event-listing-image_1_btnxps.png",
    title: "Instant and flavourful APIs: Serving up endpoints faster than 2-minute noodles",
    register: "Register",
    registerLink:
      "/events/instant-and-flavourful-apis-serving-up-endpoints-faster-than-2-minute-noodles/",
    internalLink: true,
    calendarDate: "Jun 14, 2023",
  },*/
  // {
  //   demoType: "Live Event",
  //   imgUrl:
  //     "https://res.cloudinary.com/dh8fp23nd/image/upload/v1683895597/event-thumbnail_fll2ua.png",
  //   title: "Seattle hAPI hour with Hasura",
  //   register: "Register",
  //   internalLink: true,
  //   registerLink: "/events/seattle-hapi-hour-with-hasura/",
  //   calendarDate: "Jun 15, 2023",
  // },
  /*{
    demoType: "Conference",
    imgUrl:
      "https://res.cloudinary.com/dh8fp23nd/image/upload/v1683654925/event-listing-image_je0kw6.png",
    title: "HasuraCon 2023",
    register: "Register",
    registerLink: "/events/hasura-con-2023/",
    internalLink: true,
    calendarDate: "Jun 20-22, 2023",
  },*/
  // {
  //   demoType: "Conference",
  //   imgUrl:
  //     "https://res.cloudinary.com/dh8fp23nd/image/upload/v1682592611/main-web/snowflake_summit_fg6fj1.png",
  //   title: "Snowflake Summit",
  //   register: "Register",
  //   registerLink: "https://www.snowflake.com/summit/",
  //   calendarDate: "Jun 26-29, 2023",
  // },
  // {
  //   demoType: "Webinar",
  //   imgUrl: "https://res.cloudinary.com/dh8fp23nd/image/upload/v1692197757/snowflake_c7vt47.png",
  //   title: "Accelerate app dev on Snowflake with low-code APIs",
  //   register: "Register",
  //   registerLink: "/events/webinar/accelerate-app-dev-on-snowflake-with-low-code-apis/",
  //   internalLink: true,
  //   calendarDate: "Aug 30, 2023",
  // },
  // {
  //   demoType: "Conference",
  //   imgUrl:
  //     "https://res.cloudinary.com/dh8fp23nd/image/upload/v1682592611/main-web/google_cloud_next_n8x5yk.png",
  //   title: "Google Cloud NEXT",
  //   register: "Register",
  //   registerLink: "https://hasura.io/events/google-next",
  //   internalLink: true,
  //   calendarDate: "Aug 29-31, 2023",
  // },
  // {
  //   demoType: "Conference",
  //   imgUrl: "https://res.cloudinary.com/dh8fp23nd/image/upload/v1691162951/main-web/dss_plc5tk.png",
  //   title: "Distributed SQL Summit - Virtual",
  //   register: "Register",
  //   registerLink: "https://distributedsql.org/",
  //   calendarDate: "Sep 12, 2023",
  // },
  // {
  //   demoType: "Webinar",
  //   imgUrl:
  //     "https://res.cloudinary.com/dh8fp23nd/image/upload/v1690993274/main-web/snowflake_nufwte.png",
  //   title: "Deliver applications and APIs faster with Snowflake and Hasura",
  //   register: "Register",
  //   registerLink: "/events/deliver-applications-and-apis-faster-with-snowflake-and-hasura/",
  //   internalLink: true,
  //   calendarDate: "Sep 14, 2023",
  // },
  // {
  //   demoType: "Conference",
  //   imgUrl:
  //     "https://res.cloudinary.com/dh8fp23nd/image/upload/v1682592611/main-web/graphql_conf_zj2ngt.png",
  //   title: "GraphQLConf 2023",
  //   register: "Register",
  //   registerLink: "https://graphql.org/conf/",
  //   calendarDate: "Sep 19-21, 2023",
  // },
  // {
  //   demoType: "Conference",
  //   imgUrl:
  //     "https://res.cloudinary.com/dh8fp23nd/image/upload/v1682592611/main-web/graphql_summit_p6svjb.jpg",
  //   title: "Apollo GraphQL Summit",
  //   register: "Register",
  //   registerLink: "https://summit.graphql.com/event/c51538f6-4b76-44e3-871e-54180c77cad8/summary",
  //   calendarDate: "Oct 10-12, 2023",
  // },
  // {
  //   demoType: "Live Event",
  //   imgUrl:
  //     "https://res.cloudinary.com/dh8fp23nd/image/upload/v1696311052/main-web/melbourne_txjlvt.png",
  //   title: "Mini Golf at Albert Park Golf Course with Hasura and Srijan",
  //   register: "Register",
  //   internalLink: true,
  //   registerLink: "/events/melbourne-hapi-hour-with-hasura/",
  //   calendarDate: "Oct 11, 2023",
  // },
  // {
  //   demoType: "Conference",
  //   imgUrl:
  //     "https://res.cloudinary.com/dh8fp23nd/image/upload/v1690984048/main-web/apidays-aus_o7vjyu.png",
  //   title: "Apidays Australia",
  //   register: "Register",
  //   registerLink: "https://www.apidays.global/australia/",
  //   calendarDate: "Oct 11-12, 2023",
  // },
  // {
  //   demoType: "Live Event",
  //   imgUrl:
  //     "https://res.cloudinary.com/dh8fp23nd/image/upload/v1695642527/event-thumbnail_3_hpiwb8.png",
  //   title: "Microbrews and microservices in Ft. Lauderdale",
  //   register: "Register",
  //   internalLink: true,
  //   registerLink: "/events/microbrews-and-microservices-in-ft-lauderdale/",
  //   calendarDate: "Oct 24, 2023",
  // },
  // {
  //   demoType: "Live Event",
  //   imgUrl:
  //     "https://res.cloudinary.com/dh8fp23nd/image/upload/v1695642527/event-thumbnail_vgfgs4.jpg",
  //   title: "Microbrews and microservices in Atlanta",
  //   register: "Register",
  //   internalLink: true,
  //   registerLink: "/events/microbrews-and-microservices-in-atlanta/",
  //   calendarDate: "Oct 25, 2023",
  // },
  {
    demoType: "Roundtable",
    imgUrl:
      "https://res.cloudinary.com/dh8fp23nd/image/upload/v1697545172/website%20v3/webinar-thumb_iwzeg9.png",
    title: "Rise of the Supergraph: An Architecture Roundtable",
    register: "Register",
    registerLink: "/events/roundtable/supergraph-architecture-roundtable/",
    internalLink: true,
    calendarDate: "Oct 26, 2023",
  },
  {
    demoType: "Community Call",
    imgUrl:
      "https://res.cloudinary.com/dh8fp23nd/image/upload/v1690540929/community-call_l9qcu4.png",
    title: "Hasura Community Call Launch Day - Oct 2023",
    register: "Register",
    registerLink: COMMUNITY_CALL_BASE_URL,
    internalLink: true,
    calendarDate: "Oct 26, 2023",
  },
  {
    demoType: "Live Event",
    imgUrl:
      "https://res.cloudinary.com/dh8fp23nd/image/upload/v1696866147/main-web/event-thumbnail_5_1_hr4k0e.png",
    title: " Executive Dinner Series: Life beyond Microservices - Bengaluru",
    register: "Register",
    registerLink: "/events/field-events/exec-dinner-bengaluru-oct-2023",
    internalLink: true,
    calendarDate: "Oct 27, 2023",
  },
  {
    demoType: "Conference",
    imgUrl: "https://res.cloudinary.com/dh8fp23nd/image/upload/v1691162951/main-web/dss_plc5tk.png",
    title: "DSS Day Chicago",
    register: "Register",
    registerLink: "https://distributedsql.org/",
    calendarDate: "Nov 06, 2023",
  },
  {
    demoType: "Live Event",
    imgUrl:
      "https://res.cloudinary.com/dh8fp23nd/image/upload/v1696866147/main-web/event-thumbnail_5_1_hr4k0e.png",
    title: " Executive Dinner Series: Life beyond Microservices - NYC",
    register: "Register",
    registerLink: "/events/field-events/exec-dinner-nyc-nov-2023",
    internalLink: true,
    calendarDate: "Nov 09, 2023",
  },
  {
    demoType: "Conference",
    imgUrl:
      "https://res.cloudinary.com/dh8fp23nd/image/upload/v1690984048/main-web/apidays-paris_t8txau.png",
    title: "Apidays Paris",
    register: "Register",
    registerLink: "https://www.apidays.global/paris/",
    calendarDate: "Dec 06-08, 2023",
  },
];
