import React, { Fragment, useState, useEffect } from "react";
import styled from "styled-components";

import newsletterSuccess from "./images/newsletter-success.svg";
import Paperform from "../contactus/Paperform";

import { Box } from "../../globals/UIKit";
import { StyledDisplay2Xs, StyledSubTitle1, StyledDesc1 } from "../shared/StyledTypography";
import { mq } from "../../globals/utils";
import { textCenter } from "../shared/CommonStyled";

const StyledSubscribeWrapper = styled.div`
  .gridFooter {
    display: grid;
    grid-template-columns: 1fr 300px;
    grid-gap: 40px;
    min-height: 190px;

    .alignSelfCenter {
      align-self: center;
    }
  }

  /* CLS Optimization for Paperform */
  @media (min-width: 1024px) {
    .marketoFormWrapper {
      max-height: 180px;
      overflow: hidden;
    }

    .gridFooter {
      max-height: 190px;
    }
  }

  .mWidth500 {
    max-width: 500px;
    margin: 0 auto;
    min-height: 150px;
    display: flex;
    align-items: center;
  }
  .subscribeNewsletterSuccess {
    align-items: center;
    background-color: #f8f8f8;
    border-radius: 4px;
    color: #111b29;
    display: flex;
    justify-content: center;
    padding: 20px;
    img {
      margin-right: 22px;
      width: 40px;
    }
  }
  ${mq.between("lg", "xl")} {
    .gridFooter {
      grid-gap: 20px;
    }
  }
  ${mq.between("md", "lg")} {
    .alignSelfCenter {
      text-align: center;
    }
    .gridFooter {
      grid-template-columns: 1fr;
      grid-gap: 0px;
    }
    .mWidth500 {
      min-height: unset;
    }
  }
  ${mq.below.md} {
    .gridFooter {
      grid-template-columns: 1fr;
      grid-gap: 0px;
    }
    .mWidth500 {
      min-height: unset;
    }
    .marketoFormWrapper {
      margin-top: 0 !important;
    }
  }
`;

const SubscribeNewsletter = ({ props, eventsTopBanner, footerNewsletter }) => {
  const path = props.location.pathname;
  const [isAliId, setIsAliId] = useState(false);
  const [isLocalFooterSubscribe, setIsLocalFooterSubscribe] = useState(false);

  const onSubmitCB = () => {
    if (typeof window !== undefined) {
      window.localStorage.setItem("footerSubscribeConsent", "true");
    }
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(props.location.search);
    const searchAliId = searchParams.get("aliId");
    if (searchAliId || searchAliId === "") {
      setIsAliId(true);
    }
    if (typeof window !== undefined) {
      if ("localStorage" in window && window.localStorage && "getItem" in window.localStorage) {
        const footerSubscribeConsent = window.localStorage.getItem("footerSubscribeConsent");
        if (footerSubscribeConsent) {
          setIsLocalFooterSubscribe(true);
        }
      }
    }
  }, [props.location.search]);

  const isEvents = path === "/events/";

  const getTitle = () => {
    if (isEvents && eventsTopBanner) {
      return (
        <StyledSubTitle1 paddingBottom="pb24" fontWeight="font_600">
          Join a Hasura event, from anywhere in the world.
        </StyledSubTitle1>
      );
    }
    return (
      <StyledDisplay2Xs variant="neutral_82" fontWeight="font_bold" className="alignSelfCenter">
        Monthly product updates in your inbox. No spam.
      </StyledDisplay2Xs>
    );
  };

  const getDescription = () => {
    if (isEvents && eventsTopBanner) {
      return (
        <StyledDesc1 paddingBottom="pb24">
          Learn more about our live and recorded events below. You can also sign up to our community
          newsletter to stay up-to-date on our events.
        </StyledDesc1>
      );
    }
    return null;
  };

  return (
    <StyledSubscribeWrapper id="subscribe-updates">
      {isAliId && isLocalFooterSubscribe ? (
        <Fragment>
          {footerNewsletter ? (
            <StyledDisplay2Xs
              className="mWidth500"
              variant="neutral_82"
              fontWeight="font_bold"
              css={textCenter}
            >
              Thank you for subscribing to our newsletter!
            </StyledDisplay2Xs>
          ) : (
            <div>
              {getTitle()}
              {!footerNewsletter ? getDescription() : null}{" "}
              <StyledDesc1 className="subscribeNewsletterSuccess" fontWeight="font_600">
                <img src={newsletterSuccess} alt="Success" loading="lazy" />
                Thank you for subscribing to our newsletter!
              </StyledDesc1>
            </div>
          )}
        </Fragment>
      ) : (
        <div className={footerNewsletter ? "gridFooter" : ""}>
          {getTitle()}
          {!footerNewsletter ? getDescription() : null}{" "}
          <Box height="185px" maxHeight="185px" overflow="hidden">
            <Paperform
              isLazy
              onSubmitCB={onSubmitCB}
              formId={isEvents && eventsTopBanner ? "hf-1079" : "hf-1011"}
              styleClass="marketoFormWrapper subscribeNewsletterForm"
            />
          </Box>
        </div>
      )}
    </StyledSubscribeWrapper>
  );
};

export default SubscribeNewsletter;
