import React, { Fragment } from "react";

import SubscribeNewsletter from "../common/subscribenewsletter";
import PromoBannerSmall from "./PromoBannerSmall";

import "./styles.scss";

const TopBanner = props => {
  return (
    <Fragment>
      <section className="commonSectionWrapper wd100 patternWrapper removePaddBottom removePaddTop">
        <div className="containerWrapper">
          <div className="topBannerWrapperEvents wd100">
            <div className="eventsNewsLetter commonSectionWrapper removePaddTop">
              <SubscribeNewsletter props={props} eventsTopBanner="eventsTopBanner" />
              <PromoBannerSmall />
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default TopBanner;
